<template>
  <div class="top-bar">
    <div class="logo-container">
      <img src="https://flashy-assets20211018140904806600000006.s3.eu-central-1.amazonaws.com/email/Flashy_LogoExtended.png" alt="Flashy Logo" class="logo" />
    </div>
    <div class="actions" v-if="isGeneratedImage">
      <q-btn no-caps :loading="isDownloading" @click.native="handleDownload" flat class="action-btn" color="grey-8" padding="12px">
        <template v-slot:default>
          <q-icon name="o_download" class="q-mr-sm" color="grey-7" size="xs" />
          <q-tooltip>Download the image directly to your machine</q-tooltip>
          {{ isDownloading ? 'Downloading...' : 'Download' }}
        </template>
      </q-btn>
      <q-btn no-caps :loading="isSaving" @click="handleSave" flat class="action-btn" color="grey-8" padding="12px">
        <template v-slot:default>
          <q-icon name="o_save" class="q-mr-sm" color="grey-7" size="xs" />
          {{ isSaving ? 'Saving...' : 'Save as copy' }}
          <q-tooltip>Save a copy without discarding the original</q-tooltip>
        </template>
      </q-btn>
      <q-btn no-caps @click="handleDiscard" flat class="action-btn" color="grey-8" padding="12px">
        <template v-slot:default>
          <q-icon name="o_delete" class="q-mr-sm" color="grey-7" size="xs" />
          Discard
          <q-tooltip>Discard the current result</q-tooltip>
        </template>
      </q-btn>
    </div>
    <q-btn flat round icon="close" @click="handleClose" class="close-btn" />
  </div>
</template>

<script>
import { ContentApi } from '@api/index';

export default {
  name: 'ImageEditorTopBar',
  props: {
    isGeneratedImage: {
      type: Boolean,
      default: false
    },
    image: {
      type: Object,
      required: true
    }
  },
  data() {
    return {
      isDownloading: false,
      isSaving: false
    };
  },
  methods: {
    handleClose() {
      this.$emit('update:isOpen', false);
      this.$emit('close');
    },
    async handleDownload() {
      if (this.isDownloading) return;
      this.isDownloading = true;
      try {
        const response = await fetch(this.image.optimized_url);
        const blob = await response.blob();
        const url = window.URL.createObjectURL(blob);
        const link = document.createElement('a');
        link.href = url;
        const timestamp = new Date().toISOString().replace(/[:.]/g, '-').slice(0, 19);
        link.download = `edited-image-${timestamp}.jpeg`;
        link.style.display = 'none';
        document.body.appendChild(link);
        link.click();
        document.body.removeChild(link);
        window.URL.revokeObjectURL(url);
      } catch (error) {
        console.error('Error downloading image:', error);
      } finally {
        this.isDownloading = false;
      }
    },
    async handleSave() {
      if (this.isSaving) return;
      this.isSaving = true;
      try {
        const response = await fetch(this.image.optimized_url);
        const blob = await response.blob();
        const timestamp = new Date().toISOString().replace(/[:.]/g, '-').slice(0, 19);
        const file = new File([blob], `edited-image-${timestamp}.jpeg`, { type: 'image/jpeg' });

        // Get image dimensions
        const img = new Image();
        await new Promise((resolve, reject) => {
          img.onload = resolve;
          img.onerror = reject;
          img.src = URL.createObjectURL(file);
        });

        console.log('image file', file);

        const fileData = {
          name: file.name,
          relativePath: file.name,
          contentType: file.type,
          aspectRatio: parseFloat((img.naturalWidth / img.naturalHeight).toFixed(3))
        };

        // Get presigned URL
        const urlResponse = await ContentApi.getPresignedUrls({
          id: this.image.shoot_id,
          files: [fileData]
        });

        const { presignedUrls, imagesMetadata } = urlResponse.data;

        // Upload file
        const xhr = new XMLHttpRequest();
        await new Promise((resolve, reject) => {
          xhr.open('PUT', presignedUrls[0].presignedUrl, true);
          xhr.setRequestHeader('Content-Type', file.type);

          xhr.onload = () => (xhr.status === 200 ? resolve() : reject(new Error(`Upload failed: ${xhr.status}`)));
          xhr.onerror = () => reject(new Error('Network error during upload'));
          xhr.send(file);
        });

        // Create image batch
        await ContentApi.createImagesBatch({
          id: this.image.shoot_id,
          payload: {
            images: imagesMetadata
          }
        });

        this.$store.dispatch('notification/addSuccessNotification', 'Image saved successfully');
      } catch (error) {
        console.error('Error saving image:', error);
        this.$store.dispatch('notification/addFailureNotification', `Failed to save image: ${error.message}`);
      } finally {
        this.isSaving = false;
      }
    },
    handleDiscard() {
      this.$emit('discard');
    }
  }
};
</script>

<style lang="scss" scoped>
.top-bar {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 16px 24px;
  height: 75px;
  background-color: white;

  .logo-container {
    display: flex;
    align-items: center;
    gap: 0;
  }

  .logo {
    height: 32px;
    width: auto;
    object-fit: contain;
  }

  .actions {
    display: flex;
    gap: 8px;
    margin-left: auto;
    margin-right: 16px;
  }

  .action-btn {
    border-radius: 8px;
    font-weight: 500;
    font-size: 14px;
    background: #f3f4f6;
  }

  .close-btn {
    width: 40px;
    height: 40px;
    border-radius: 8px;
    color: #151515;
    transition: all 0.2s ease;

    &:hover {
      background: #f0e6ff;
      color: #8700ff;
      transform: scale(1.05);
    }

    &:active {
      transform: scale(0.95);
    }
  }
}
</style>
