<script>
import { ReplicateApi } from '@api/index';
import ImageEditorTopBar from './editor/ImageEditorTopBar.vue';
import ImageEditorSidebar from './editor/ImageEditorSidebar.vue';
import ImageEditorCanvas from './editor/ImageEditorCanvas.vue';
import Compressor from 'compressorjs';
import axios from 'axios';

export default {
  name: 'ImageEditor',
  components: {
    ImageEditorTopBar,
    ImageEditorSidebar,
    ImageEditorCanvas
  },
  props: {
    image: {
      type: Object,
      required: true
    },
    isOpen: {
      type: Boolean,
      required: true
    }
  },
  data() {
    return {
      brushSize: 150,
      brushColor: '#8800FF',
      cursorSize: 50,
      isDrawing: false,
      startX: 0,
      startY: 0,
      canvasWidth: 800,
      imageLoaded: false,
      prompt: '',
      negativePrompt: '',
      numResults: 1,
      editingHistory: [],
      currentHistoryIndex: -1,
      isGenerating: false,
      isRemoving: false,
      isOutpainting: false,
      operationStatus: 'preparing'
    };
  },
  watch: {
    currentHistoryIndex(newIndex) {
      // Adjust brush size when switching between original and generated images
      if (newIndex > 0) {
        // For generated images (1440p), set brush size between 25-150
        this.brushSize = Math.min(Math.max(this.brushSize, 25), 150);
      } else {
        // For original image (4K+), set brush size between 150-650
        this.brushSize = Math.min(Math.max(this.brushSize, 150), 650);
      }
    }
  },
  mounted() {
    if (this.image && this.image.optimized_url) {
      // Initialize editing history with original image
      this.editingHistory = [{ url: this.image.optimized_url, placeholder_url: this.image.preview_url, type: 'original' }];
      this.currentHistoryIndex = 0;
    }
  },
  methods: {
    clearMask() {
      if (this.$refs.canvas) {
        this.$refs.canvas.clearCanvas();
      }
    },

    async optimizeImage(blob, referenceWidth = null, referenceHeight = null) {
      try {
        console.log(`Optimizing ${referenceHeight ? 'mask:' : 'image:'}`, blob);
        // Load image to get dimensions
        const img = new Image();
        await new Promise(resolve => {
          img.onload = resolve;
          img.src = URL.createObjectURL(blob);
        });

        // Define max resolution (1440p)
        const MAX_HEIGHT = 1440;
        const MAX_WIDTH = 2560;

        // Use reference dimensions if provided, otherwise calculate from constraints
        let targetWidth, targetHeight;

        if (referenceWidth && referenceHeight) {
          targetWidth = referenceWidth;
          targetHeight = referenceHeight;
        } else {
          const aspectRatio = img.width / img.height;
          targetWidth = img.width;
          targetHeight = img.height;

          // Check if image exceeds 1440p resolution
          if (img.height > MAX_HEIGHT || img.width > MAX_WIDTH) {
            if (aspectRatio > 1) {
              // Landscape orientation
              targetWidth = Math.min(MAX_WIDTH, img.width);
              targetHeight = Math.round(targetWidth / aspectRatio);
            } else {
              // Portrait orientation
              targetHeight = Math.min(MAX_HEIGHT, img.height);
              targetWidth = Math.round(targetHeight * aspectRatio);
            }
          }
        }

        // Ensure dimensions are integers
        targetWidth = Math.floor(targetWidth);
        targetHeight = Math.floor(targetHeight);

        // Compress image using compressor.js
        return new Promise((resolve, reject) => {
          new Compressor(blob, {
            width: targetWidth,
            height: targetHeight,
            resize: 'cover',
            strict: true, // Ensure output matches target dimensions exactly
            success: result => {
              resolve(result);
            },
            error: reject
          });
        });
      } catch (error) {
        console.error('Error optimizing image:', error);
        throw error;
      }
    },

    async removeObjects() {
      try {
        this.isRemoving = true;
        // Extract the mask blob from the canvas
        const mask = await this.$refs.canvas.extractMask();
        this.$refs.canvas.clearCanvas();

        // Download the image & get the blob
        const response = await axios.get(this.image.optimized_url, {
          responseType: 'blob'
        });
        const imageBlob = response.data;
        const image = new Blob([imageBlob], { type: this.currentHistoryIndex === 0 ? 'image/webp' : 'image/jpeg' });

        const result = await ReplicateApi.removeObjects({ imageId: this.image.id, image, mask });

        if (result.data && result.data.prediction_id) {
          await this.pollPredictionStatus(result.data.prediction_id);
        } else {
          throw new Error('No prediction ID in the response');
        }
      } catch (error) {
        console.error('Error in removeObjects:', error);
      } finally {
        this.isRemoving = false;
      }
    },
    async generateObjects() {
      if (!this.prompt) {
        console.warn('Please provide a prompt for generation');
        return;
      }

      try {
        this.isGenerating = true;
        // Extract the mask blob from the canvas
        const mask = await this.$refs.canvas.extractMask();
        this.$refs.canvas.clearCanvas();

        // Download the image & get the blob
        const response = await axios.get(this.image.optimized_url, {
          responseType: 'blob'
        });
        const imageBlob = response.data;
        const image = new Blob([imageBlob], { type: this.currentHistoryIndex === 0 ? 'image/webp' : 'image/jpeg' });

        // Call ReplicateApi
        const result = await ReplicateApi.generateObjects({
          imageId: this.image.id,
          image,
          mask,
          prompt: this.prompt
        });

        if (result.data && result.data.prediction_id) {
          await this.pollPredictionStatus(result.data.prediction_id);
        } else {
          throw new Error('No prediction ID in the response');
        }
      } catch (error) {
        console.error('Error in generative fill:', error);
      } finally {
        this.isGenerating = false;
      }
    },
    async outpaint(direction) {
      console.log('Outpainting with direction:', direction);
      try {
        this.isOutpainting = true;
        // Extract the mask blob from the canvas
        this.$refs.canvas.clearCanvas();

        // Download the image & get the blob
        const response = await axios.get(this.image.optimized_url, {
          responseType: 'blob'
        });
        const imageBlob = response.data;
        const image = new Blob([imageBlob], { type: this.currentHistoryIndex === 0 ? 'image/webp' : 'image/jpeg' });

        // Call ReplicateApi with direction
        const result = await ReplicateApi.outpaint({
          imageId: this.image.id,
          image,
          outpaint: direction,
          prompt: this.prompt
        });

        if (result.data && result.data.prediction_id) {
          await this.pollPredictionStatus(result.data.prediction_id);
        } else {
          throw new Error('No prediction ID in the response');
        }
      } catch (error) {
        console.error('Error in outpainting:', error);
      } finally {
        this.isOutpainting = false;
      }
    },
    async removeBackground() {
      try {
        this.isRemoving = true;

        // Download the image & get the blob
        const response = await axios.get(this.image.optimized_url, {
          responseType: 'blob'
        });
        const imageBlob = response.data;
        const image = new Blob([imageBlob], { type: 'image/webp' });

        // Call ReplicateApi
        const result = await ReplicateApi.removeBackground({
          imageId: this.image.id,
          image
        });

        if (result.data && result.data.prediction_id) {
          await this.pollPredictionStatus(result.data.prediction_id);
        } else {
          throw new Error('No prediction ID in the response');
        }
      } catch (error) {
        console.error('Error in background removal:', error);
      } finally {
        this.isRemoving = false;
      }
    },

    async pollPredictionStatus(predictionId) {
      const pollInterval = 2000;
      const maxAttempts = 100;
      let attempts = 0;

      while (attempts < maxAttempts) {
        try {
          const result = await ReplicateApi.getPredictionStatus(this.image.id, predictionId);
          const { prediction } = result.data;

          // Update operation status for UI feedback

          switch (prediction.status) {
            case 'starting':
              // Cold boot or initialization phase
              this.operationStatus = 'Starting operation';
              break;

            case 'processing':
              // Model is actively processing
              this.operationStatus = 'Processing image';
              break;

            case 'succeeded':
              this.operationStatus = 'Operation completed';
              if (prediction.output) {
                // Handle both single image and array of images
                const outputs = Array.isArray(prediction.output) ? prediction.output : [prediction.output];

                // Add all generated images to history with additional metadata
                outputs.forEach((output, index) => {
                  this.editingHistory.push({
                    url: output,
                    placeholder_url: this.image.preview_url,
                    type: 'edited',
                    timestamp: Date.now(),
                    isVariant: outputs.length > 1,
                    variantIndex: index + 1,
                    metrics: prediction.metrics,
                    created_at: prediction.created_at,
                    completed_at: prediction.completed_at
                  });
                });

                // Update current history index to point to the last generated image
                this.currentHistoryIndex = this.editingHistory.length - 1;
                // Display the last generated image
                this.image.optimized_url = outputs[outputs.length - 1];
                this.operationStatus = null;
                return;
              }
              break;

            case 'failed':
              this.operationError = prediction.error || 'Prediction failed';
              throw new Error(prediction.error || 'Prediction failed');

            case 'canceled':
              console.log('Prediction was canceled');
              throw new Error('Prediction was canceled by the user');

            default:
              console.warn('Unknown prediction status:', prediction.status);
          }

          attempts++;
          await new Promise(resolve => setTimeout(resolve, pollInterval));
        } catch (error) {
          this.operationStatus = error.message;
          throw error;
        }
      }

      this.operationStatus = 'Operation timed out';
      throw new Error('Operation timed out');
    },
    selectHistoryImage(index) {
      if (index >= 0 && index < this.editingHistory.length) {
        this.currentHistoryIndex = index;
        this.image.optimized_url = this.editingHistory[index].url;
      }
    },
    handleDiscard() {
      if (this.currentHistoryIndex > 0) {
        // Remove current image from history
        this.editingHistory.splice(this.currentHistoryIndex, 1);

        // Update current history index to point to previous image
        this.currentHistoryIndex = this.currentHistoryIndex - 1;

        // Restore previous image
        this.image.optimized_url = this.editingHistory[this.currentHistoryIndex].url;

        // Reinitialize canvas with previous image

        this.$refs.canvas.initializeCanvas();
      }
    }
  }
};
</script>

<template>
  <q-dialog v-model="isOpen" persistent maximized transition-show="slide-up" transition-hide="slide-down">
    <ImageEditorTopBar :is-generated-image="currentHistoryIndex > 0" :image="image" @discard="handleDiscard" @update:isOpen="$emit('update:isOpen', $event)" />
    <q-card class="editor-container">
      <ImageEditorSidebar
        :brush-size.sync="brushSize"
        :prompt.sync="prompt"
        :editing-history="editingHistory"
        :current-history-index="currentHistoryIndex"
        :is-removing="isRemoving"
        :is-generating="isGenerating"
        :is-outpainting="isOutpainting"
        :operation-status="operationStatus"
        @updateCursor="$refs.canvas.updateCursor()"
        @removeObjects="removeObjects"
        @removeBackground="removeBackground"
        @clearMask="clearMask"
        @generateFill="generateObjects"
        @outpaint="outpaint"
        @selectHistoryImage="selectHistoryImage"
      />
      <ImageEditorCanvas ref="canvas" :image="image" :brush-size="brushSize" :brush-color="brushColor" />
    </q-card>
  </q-dialog>
</template>

<style lang="scss" scoped>
.editor-container {
  background: #f8f9fa;
  min-height: 100vh;
  display: grid;
  grid-template-columns: 360px 1fr;
  padding: 24px;
  gap: 24px;
  background: linear-gradient(to bottom right, #f8f9fa, #ffffff);
}

.sidebar {
  background: white;
  border-radius: 12px;
  padding: 24px;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.05);
  height: calc(100vh - 48px - 75px) !important;
  overflow-y: auto;
}

.image-container {
  position: relative;
  width: 100%;
  height: calc(100vh - 48px - 75px);
  display: flex;
  align-items: center;
  justify-content: center;
}

.image-preview {
  max-height: 100%;
}

.brush-canvas {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  width: 100%;
  height: 100%;
  opacity: 0.6;
  cursor: crosshair;
  pointer-events: all;
}

.top-bar {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 16px 24px;
  height: 75px;
  background-color: white;

  .logo-container {
    display: flex;
    align-items: center;
    gap: 0;
  }

  .logo {
    height: 32px;
    width: auto;
    object-fit: contain;
  }

  .edits-text {
    font-size: 24px;
    font-weight: bold;
    background: linear-gradient(90deg, #8700ff, #00c2ff, #8700ff);
    background-size: 200% auto;
    color: transparent;
    -webkit-background-clip: text;
    background-clip: text;
    animation: gradient 3s linear infinite;
    transition: transform 0.3s ease;
    margin: 0;

    &:hover {
      transform: scale(1.1);
    }
  }

  .close-btn {
    margin-left: auto;
    width: 40px;
    height: 40px;
    border-radius: 8px;
    color: #151515;
    transition: all 0.2s ease;

    &:hover {
      background: #f0e6ff;
      color: #8700ff;
      transform: scale(1.05);
    }

    &:active {
      transform: scale(0.95);
    }
  }

  @keyframes gradient {
    0% {
      background-position: 0% center;
    }
    100% {
      background-position: 200% center;
    }
  }
}
</style>
