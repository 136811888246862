<template>
  <div class="image-container">
    <q-img :src="image.optimized_url" :placeholder-src="image.preview_url" class="image-preview" :contain="true" @load="onImageLoad" />
    <canvas v-if="imageLoaded" ref="brushCanvas" class="brush-canvas" @mousedown="startDrawing" @mousemove="draw" @mouseup="stopDrawing" @mouseleave="stopDrawing" />
  </div>
</template>

<script>
export default {
  name: 'ImageEditorCanvas',
  props: {
    image: {
      type: Object,
      required: true
    },
    brushSize: {
      type: Number,
      required: true
    },
    brushColor: {
      type: String,
      required: true
    }
  },
  data() {
    return {
      isDrawing: false,
      startX: 0,
      startY: 0,
      imageLoaded: false
    };
  },
  mounted() {
    // Remove initialization here since we'll do it after image loads
  },
  methods: {
    clearCanvas() {
      const brushCanvas = this.$refs.brushCanvas;
      if (brushCanvas) {
        const ctx = brushCanvas.getContext('2d');
        ctx.clearRect(0, 0, brushCanvas.width, brushCanvas.height);
      }
    },
    initializeCanvas() {
      const brushCanvas = this.$refs.brushCanvas;
      if (!brushCanvas) return;

      this.updateCursor();

      // Get the image element from q-img component
      const imageContainer = brushCanvas.parentElement;
      const imageElement = imageContainer.querySelector('.q-img__image');
      if (!imageElement) return;

      // Get the natural dimensions of the original image
      const img = new Image();
      img.src = this.image.optimized_url;

      // Set canvas dimensions to match the original image dimensions
      brushCanvas.width = img.naturalWidth || imageElement.naturalWidth;
      brushCanvas.height = img.naturalHeight || imageElement.naturalHeight;

      // Calculate the display size while maintaining aspect ratio
      const containerRect = imageContainer.getBoundingClientRect();
      const imageAspectRatio = brushCanvas.width / brushCanvas.height;
      let displayWidth, displayHeight;

      if (imageAspectRatio > 1) {
        // Landscape
        displayWidth = Math.min(containerRect.width, containerRect.height * imageAspectRatio);
        displayHeight = displayWidth / imageAspectRatio;
      } else {
        // Portrait or square
        displayHeight = Math.min(containerRect.height, containerRect.width / imageAspectRatio);
        displayWidth = displayHeight * imageAspectRatio;
      }

      // Update canvas display size
      brushCanvas.style.width = `${displayWidth}px`;
      brushCanvas.style.height = `${displayHeight}px`;

      // Initialize brush canvas
      const brushCtx = brushCanvas.getContext('2d');
      brushCtx.lineJoin = 'round';
      brushCtx.lineCap = 'round';
      brushCtx.strokeStyle = this.brushColor;
    },
    onImageLoad() {
      this.imageLoaded = true;
      this.$nextTick(() => {
        this.initializeCanvas();
        this.updateCursor();
      });
    },
    startDrawing(e) {
      const brushCanvas = this.$refs.brushCanvas;
      const brushContext = brushCanvas.getContext('2d');

      // Clear canvas if starting new stroke
      brushContext.beginPath();

      this.isDrawing = true;
      const rect = brushCanvas.getBoundingClientRect();

      // Calculate scale factors
      const scaleX = brushCanvas.width / rect.width;
      const scaleY = brushCanvas.height / rect.height;

      // Apply scaling to coordinates
      this.startX = (e.clientX - rect.left) * scaleX;
      this.startY = (e.clientY - rect.top) * scaleY;
    },
    draw(e) {
      if (!this.isDrawing) return;

      const brushCanvas = this.$refs.brushCanvas;
      const brushContext = brushCanvas.getContext('2d');
      const rect = brushCanvas.getBoundingClientRect();

      // Calculate scale factors
      const scaleX = brushCanvas.width / rect.width;
      const scaleY = brushCanvas.height / rect.height;

      // Apply scaling to coordinates
      const x = (e.clientX - rect.left) * scaleX;
      const y = (e.clientY - rect.top) * scaleY;

      brushContext.lineWidth = this.brushSize;
      brushContext.strokeStyle = this.brushColor;
      brushContext.globalCompositeOperation = 'source-over';

      brushContext.beginPath();
      brushContext.moveTo(this.startX, this.startY);
      brushContext.lineTo(x, y);
      brushContext.stroke();

      this.startX = x;
      this.startY = y;
    },
    stopDrawing() {
      this.isDrawing = false;
    },
    updateCursor() {
      const brushCanvas = this.$refs.brushCanvas;
      if (!brushCanvas) return;

      // Scale the cursor size relative to the canvas dimensions
      const rect = brushCanvas.getBoundingClientRect();
      const scaleX = brushCanvas.width / rect.width;
      const displaySize = Math.max(8, this.brushSize / scaleX);

      const color = this.brushColor.replace('#', '%23');
      const svg = `data:image/svg+xml;utf8,<svg xmlns="http://www.w3.org/2000/svg" width="${displaySize}" height="${displaySize}" viewBox="0 0 ${displaySize} ${displaySize}"><circle cx="${
        displaySize / 2
      }" cy="${displaySize / 2}" r="${displaySize / 2 - 1}" fill="none" stroke="${color}" stroke-width="1"/></svg>`;

      brushCanvas.style.cursor = `url('${svg}') ${displaySize / 2} ${displaySize / 2}, crosshair`;
    },
    extractMask() {
      return new Promise(resolve => {
        const brushCanvas = this.$refs.brushCanvas;
        const brushContext = brushCanvas.getContext('2d');
        const imageData = brushContext.getImageData(0, 0, brushCanvas.width, brushCanvas.height);

        const maskCanvas = document.createElement('canvas');
        const maskContext = maskCanvas.getContext('2d');

        maskCanvas.width = brushCanvas.width;
        maskCanvas.height = brushCanvas.height;

        for (let i = 0; i < imageData.data.length; i += 4) {
          const isFilled = imageData.data[i + 3] !== 0;
          const maskValue = isFilled ? 255 : 0;

          imageData.data[i] = maskValue;
          imageData.data[i + 1] = maskValue;
          imageData.data[i + 2] = maskValue;
          imageData.data[i + 3] = 255;
        }

        maskContext.putImageData(imageData, 0, 0);
        maskCanvas.toBlob(
          blob => {
            resolve(blob);
          },
          'image/png',
          1.0
        ); // Use PNG format with maximum quality to preserve exact dimensions
      });
    }
  },
  watch: {
    'image.optimized_url'() {
      this.$nextTick(() => {
        this.initializeCanvas();
      });
    },
    brushColor() {
      this.updateCursor();
    },
    brushSize() {
      this.updateCursor();
    }
  }
};
</script>

<style lang="scss" scoped>
.image-container {
  position: relative;
  width: 100%;
  height: calc(100vh - 48px - 75px - 68px);
  display: flex;
  align-items: center;
  justify-content: center;
}

.image-preview {
  max-height: 100%;
}

.brush-canvas {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  width: 100%;
  height: 100%;
  opacity: 0.6;
  cursor: crosshair;
  pointer-events: all;
}
</style>
