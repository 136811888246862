<template>
  <div class="sidebar">
    <div class="sidebar-header">
      <p class="title">Image editor</p>
      <p class="description">Enhance your image with powerful AI tools.</p>
    </div>

    <div class="toolbox">
      <q-btn
        v-for="tool in tools"
        :key="tool.id"
        @click="tool.disabled ? null : (activeTool = tool.id)"
        :class="['tool-button', { active: activeTool === tool.id, disabled: tool.disabled }]"
        :title="tool.name"
        flat
        no-caps
        :disable="tool.disabled"
      >
        <QIcon :name="`o_${tool.icon}`" size="20px" :color="tool.disabled ? 'grey-5' : 'grey-7'" />
        <q-tooltip>{{ tool.name }}</q-tooltip>
      </q-btn>
    </div>

    <div class="control-section">
      <!-- Inpaint Controls -->
      <template v-if="activeTool === 'removeObjects' || activeTool === 'generate'">
        <div class="control-header">
          <label class="input-caption">Brush size</label>
          <span class="value-badge">{{ brushSize }}</span>
        </div>
        <div class="range-container">
          <span class="range-label">15</span>
          <input type="range" :value="brushSize" @input="updateBrushSize" min="15" max="150" class="range-slider" />
          <span class="range-label">150</span>
        </div>

        <q-btn class="action-button action-button--secondary mt-4 mb-3" @click="clearMask" flat no-caps> <QIcon name="o_delete" color="grey-7" size="xs" class="button-icon" /> Clear canvas </q-btn>
      </template>

      <!-- Remove Background -->
      <template v-if="activeTool === 'removeBackground'">
        <q-btn class="action-button action-button--primary" :disable="isRemoving" @click="removeBackground" flat no-caps>
          <template v-if="isRemoving"><q-spinner color="grey-7" size="sm" style="margin-right: 8px" /> {{ operationStatus }}</template>
          <template v-else> <QIcon name="content_cut" size="xs" class="button-icon" color="grey-7" /> Remove background </template>
        </q-btn>
      </template>

      <!-- Generate Background -->
      <template v-if="activeTool === 'generateBackground'">
        <q-btn class="action-button action-button--primary" @click="generateBackground" :disable="isGeneratingBackground" flat no-caps>
          <q-spinner-dots v-if="isGeneratingBackground" color="white" size="1.5em" />
          <template v-else> <QIcon name="auto_awesome" size="xs" class="button-icon" color="grey-7" /> Generate background </template>
        </q-btn>
      </template>

      <!-- Outpainting -->
      <template v-if="activeTool === 'outpainting'">
        <div class="control-header">
          <label class="input-caption">Outpainting options</label>
        </div>
        <div class="outpainting-options">
          <q-btn class="action-button action-button--secondary mb-3" @click="selectOutpaintingMode('Zoom out 1.5x')" :class="{ active: selectedOutpaintingMode === 'Zoom out 1.5x' }" flat no-caps>
            <QIcon name="zoom_out" size="xs" class="button-icon" color="grey-7" /> Zoom out 1.5x
          </q-btn>
          <q-btn class="action-button action-button--secondary mb-3" @click="selectOutpaintingMode('Zoom out 2x')" :class="{ active: selectedOutpaintingMode === 'Zoom out 2x' }" flat no-caps>
            <QIcon name="zoom_out" size="xs" class="button-icon" color="grey-7" /> Zoom out 2x
          </q-btn>
          <q-btn class="action-button action-button--secondary mb-3" @click="selectOutpaintingMode('Make square')" :class="{ active: selectedOutpaintingMode === 'Make square' }" flat no-caps>
            <QIcon name="crop_square" size="xs" class="button-icon" color="grey-7" /> Make square
          </q-btn>
        </div>
        <div class="control-header">
          <label class="input-caption">Direction</label>
        </div>
        <div class="outpainting-directions">
          <q-btn class="direction-button" @click="selectOutpaintingMode('Left outpaint')" :class="{ active: selectedOutpaintingMode === 'Left outpaint' }" flat no-caps>
            <QIcon name="arrow_back" size="xs" color="grey-7" />
            <q-tooltip>Extend image to the left</q-tooltip>
          </q-btn>
          <q-btn class="direction-button" @click="selectOutpaintingMode('Right outpaint')" :class="{ active: selectedOutpaintingMode === 'Right outpaint' }" flat no-caps>
            <QIcon name="arrow_forward" size="xs" color="grey-7" />
            <q-tooltip>Extend image to the right</q-tooltip>
          </q-btn>
          <q-btn class="direction-button" @click="selectOutpaintingMode('Top outpaint')" :class="{ active: selectedOutpaintingMode === 'Top outpaint' }" flat no-caps>
            <QIcon name="arrow_upward" size="xs" color="grey-7" />
            <q-tooltip>Extend image upward</q-tooltip>
          </q-btn>
          <q-btn class="direction-button" @click="selectOutpaintingMode('Bottom outpaint')" :class="{ active: selectedOutpaintingMode === 'Bottom outpaint' }" flat no-caps>
            <QIcon name="arrow_downward" size="xs" color="grey-7" />
            <q-tooltip>Extend image downward</q-tooltip>
          </q-btn>
        </div>

        <input v-model="prompt" type="text" class="text-input" placeholder="Describe the extended area" @input="handlePrompt" />

        <q-btn class="action-button action-button--primary mt-4" :disable="!selectedOutpaintingMode || isOutpainting" @click="handleOutpainting(selectedOutpaintingMode)" flat no-caps>
          <template v-if="isOutpainting"><q-spinner color="grey-7" size="sm" style="margin-right: 8px" /> Processing...</template>
          <template v-else><QIcon name="auto_awesome" size="xs" class="button-icon" color="grey-7" /> Apply outpainting</template>
        </q-btn>
      </template>

      <!-- Remove Objects -->
      <template v-if="activeTool === 'removeObjects'">
        <q-btn class="action-button action-button--primary" :disable="isRemoving" @click="removeObjects" flat no-caps>
          <template v-if="isRemoving"><q-spinner color="grey-7" size="sm" style="margin-right: 8px" /> {{ operationStatus }}</template>
          <template v-else> <QIcon name="healing" size="xs" class="button-icon" color="grey-7" /> Remove objects </template>
        </q-btn>
      </template>

      <!-- Generative Fill -->
      <template v-if="activeTool === 'generate'">
        <div class="control-header">
          <label class="input-caption">Generative fill</label>
          <QIcon name="help" size="xs" class="help-icon" color="grey-7" />
        </div>
        <input v-model="prompt" type="text" class="text-input" placeholder="Describe what you want to generate" @input="handlePrompt" />

        <q-btn class="action-button action-button--primary mt-4" :disable="isGenerating" @click="handleGenerate" flat no-caps :loading="isGenerating">
          <template v-if="isRemoving"><q-spinner color="grey-7" size="sm" style="margin-right: 8px" /> {{ operationStatus }}</template>
          <template v-else> <QIcon name="auto_fix_high" size="xs" class="button-icon" color="grey-7" /> Generate </template>
        </q-btn>
      </template>
    </div>

    <div class="history-section">
      <label class="input-caption">Generation history</label>
      <div class="history-list">
        <div v-for="(item, index) in editingHistory" :key="index" class="history-item" :class="{ active: index === currentHistoryIndex }" @click="selectHistoryImage(index)">
          <q-img :src="item.url" :placeholder-src="item.placeholder_url" class="image-preview" :contain="true" />
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { QIcon, QSpinnerDots } from 'quasar';

export default {
  name: 'ImageEditorSidebar',
  components: {
    QIcon,
    QSpinnerDots
  },
  props: {
    editingHistory: {
      type: Array,
      required: true
    },
    currentHistoryIndex: {
      type: Number,
      required: true
    },
    isRemoving: {
      type: Boolean,
      required: true
    },
    isGenerating: {
      type: Boolean,
      required: true
    },
    isGeneratingBackground: {
      type: Boolean,
      required: false,
      default: false
    },
    isOutpainting: {
      type: Boolean,
      required: false,
      default: false
    },
    operationStatus: {
      type: String,
      required: false
    }
  },
  data() {
    return {
      activeTool: 'removeObjects',
      brushSize: 15,
      selectedOutpaintingMode: '',
      prompt: '',
      tools: [
        { id: 'removeObjects', name: 'Remove objects', icon: 'healing' },
        { id: 'removeBackground', name: 'Remove background', icon: 'content_cut' },
        { id: 'outpainting', name: 'Outpainting', icon: 'zoom_out_map' },
        { id: 'generate', name: 'Generative fill', icon: 'auto_fix_high' },
        { id: 'generateBackground', name: 'Generate background (Coming Soon)', icon: 'auto_awesome', disabled: true }
      ]
    };
  },
  methods: {
    updateBrushSize(event) {
      this.brushSize = Number(event.target.value);
      this.$emit('update:brushSize', this.brushSize);
    },
    clearMask() {
      this.$emit('clearMask');
    },
    removeBackground() {
      this.$emit('removeBackground');
    },
    generateBackground() {
      this.$emit('generateBackground');
    },
    selectOutpaintingMode(mode) {
      this.selectedOutpaintingMode = mode;
    },
    handleOutpainting(direction) {
      console.log('Emitting outpaint with direction:', direction);
      this.$emit('outpaint', direction);
    },
    removeObjects() {
      this.$emit('removeObjects');
    },
    handlePrompt() {
      this.$emit('update:prompt', this.prompt);
    },
    handleGenerate() {
      this.$emit('generateFill');
    },
    selectHistoryImage(index) {
      this.$emit('selectHistoryImage', index);
    }
  }
};
</script>

<style lang="scss" scoped>
p,
label {
  @include inter-font();
}

/* The style section remains unchanged */
.sidebar {
  background: #ffffff;
  border-radius: 12px;
  padding: 24px;
  box-shadow: 0 4px 20px rgba(0, 0, 0, 0.1);
  height: calc(100vh - 48px - 75px);
  overflow-y: auto;
  font-family: 'Inter', sans-serif;
}

.sidebar-header {
  margin-bottom: 24px;

  .title {
    font-size: 24px;
    font-weight: 700;
    color: #333;
    margin-bottom: 8px;
  }

  .description {
    color: #666;
    font-size: 14px;
    line-height: 1.4;
    margin: 0;
  }
}

.toolbox {
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  gap: 12px;
  margin-bottom: 24px;
  background: #f3f4f6;
  border-radius: 12px;
  padding: 12px;
}

.tool-button {
  background: #ffffff;
  border: none;
  border-radius: 8px;
  padding: 8px;
  cursor: pointer;
  transition: all 0.2s ease;
  display: flex;
  align-items: center;
  justify-content: center;

  &:hover {
    background: #f9fafb;
  }

  &.active {
    background: linear-gradient(135deg, #ffffff, #f0e6ff);
    border: 1px solid #e6d5ff;
    box-shadow: 0 2px 4px rgba(230, 213, 255, 0.3);
  }
}

.control-section {
  margin-bottom: 24px;
}

.control-header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 12px;
}

.input-caption {
  font-size: 14px;
  font-weight: 500;
  color: #333;
  margin-bottom: 12px;
  display: block;
}

.value-badge {
  background: #fecb2f;
  color: #333333;
  padding: 2px 8px;
  border-radius: 12px;
  font-size: 14px;
  font-weight: 500;
}

.range-container {
  display: flex;
  align-items: center;
  gap: 12px;
  margin-bottom: 16px;
}

.range-label {
  font-size: 12px;
  color: #666;
  min-width: 32px;
}

.range-slider {
  flex: 1;
  height: 4px;
  border-radius: 2px;
  outline: none;
  -webkit-appearance: none;
  background: #e5e7eb;

  &::-webkit-slider-thumb {
    -webkit-appearance: none;
    width: 16px;
    height: 16px;
    background: #fecb2f;
    border-radius: 50%;
    cursor: pointer;
    transition: transform 0.2s ease;
    border: 2px solid white;
    box-shadow: 0 2px 4px rgba(254, 203, 47, 0.5);

    &:hover {
      transform: scale(1.1);
    }
  }
}

.text-input {
  width: 100%;
  padding: 10px 12px;
  border: 1px solid #e5e7eb;
  border-radius: 8px;
  font-size: 14px;
  transition: border-color 0.2s ease;

  &:focus {
    border-color: #fecb2f;
    outline: none;
    box-shadow: 0 0 0 2px rgba(254, 203, 47, 0.2);
  }

  &::placeholder {
    color: #9ca3af;
  }
}

.action-button {
  width: 100%;
  padding: 8px 12px;
  border: none;
  border-radius: 8px;
  cursor: pointer;
  font-weight: 500;
  font-size: 13px;
  transition: all 0.2s ease;
  display: flex;
  align-items: center;
  justify-content: center;

  &--primary {
    background: #fecb2f;
    color: #333333;

    &:hover {
      background: #fed95f;
    }
  }

  &--secondary {
    background: #f3f4f6;

    color: #333;

    &:hover {
      background: #e5e7eb;
    }
    &.active {
      background: linear-gradient(135deg, #ffffff, #f0e6ff) !important;
      border: 1px solid #e6d5ff !important;
      box-shadow: 0 2px 4px rgba(230, 213, 255, 0.3) !important;
    }
  }

  &:disabled {
    opacity: 0.5;
    cursor: not-allowed;
  }

  &.active {
    background: #e5e7eb;
    color: #333333;
    border: 1px solid #fecb2f;
  }
}

.button-icon {
  margin-right: 8px;
}

.help-icon {
  color: #9ca3af;
  cursor: pointer;
}

.history-section {
  margin-top: 24px;
  padding-top: 24px;
  border-top: 1px solid #e5e7eb;
}

.history-list {
  display: flex;
  gap: 12px;
  overflow-x: auto;
  padding-bottom: 12px;
  margin-top: 12px;
  scrollbar-width: thin;
  scrollbar-color: #fecb2f #f3f4f6;

  &::-webkit-scrollbar {
    height: 6px;
  }

  &::-webkit-scrollbar-track {
    background: #f3f4f6;
    border-radius: 3px;
  }

  &::-webkit-scrollbar-thumb {
    background-color: #fecb2f;
    border-radius: 3px;
  }
}

.history-item {
  flex: 0 0 auto;
  width: 120px;
  cursor: pointer;
  position: relative;
  border-radius: 3px;
  overflow: hidden;
  border: 1px solid transparent;
  transition: border-color 0.2s ease;

  &.active {
    border-color: #fecb2f;
  }
}

.outpainting-directions {
  display: grid;
  grid-template-columns: repeat(4, 1fr);
  gap: 12px;
  margin-bottom: 16px;
}

.direction-button {
  border: none;
  border-radius: 8px;
  padding: 8px;
  cursor: pointer;
  transition: all 0.2s ease;
  display: flex;
  align-items: center;
  justify-content: center;

  background: #f3f4f6;

  &:hover {
    background: #e5e7eb;
  }

  &.active {
    background: linear-gradient(135deg, #ffffff, #f0e6ff) !important;
    border: 1px solid #e6d5ff !important;
    box-shadow: 0 2px 4px rgba(230, 213, 255, 0.3) !important;
  }
}

.mt-4 {
  margin-top: 16px;
}

.mb-3 {
  margin-bottom: 12px;
}
</style>
