import { render, staticRenderFns } from "./ImageEditorSidebar.vue?vue&type=template&id=755a2910&scoped=true&"
import script from "./ImageEditorSidebar.vue?vue&type=script&lang=js&"
export * from "./ImageEditorSidebar.vue?vue&type=script&lang=js&"
import style0 from "./ImageEditorSidebar.vue?vue&type=style&index=0&id=755a2910&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "755a2910",
  null
  
)

export default component.exports
import QBtn from 'quasar/src/components/btn/QBtn.js';
import QTooltip from 'quasar/src/components/tooltip/QTooltip.js';
import QSpinner from 'quasar/src/components/spinner/QSpinner.js';
import QSpinnerDots from 'quasar/src/components/spinner/QSpinnerDots.js';
import QImg from 'quasar/src/components/img/QImg.js';
import qInstall from "../../../node_modules/vue-cli-plugin-quasar/lib/runtime.auto-import.js";
qInstall(component, 'components', {QBtn,QTooltip,QSpinner,QSpinnerDots,QImg});
